<template>
  <transition name="fade" mode="out-in">
    <div class="new-template-one-page-container">
      <nav-component></nav-component>
      <div class="contact-hero">
        <h2>BECOME A VENDOR</h2>
      </div>
      <colours-bar></colours-bar>
      <div class="contact-form-container">
        <div class="contact-form">
          <p class="learn-more-heading charcoal">Apply to become a vendor</p>
          <p class="charcoal center-align">Complete the form below to partner with us!</p>

          <div id="hubspotVendorFormTwo"></div>
          
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
  export default {
    data: function () {
      return {
        name: 'become-a-vendor-2'
      }
    },
    mounted () {
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "9304889",
            formId: "71bb60cd-da86-4012-9c42-1c2566712367",
            target: "#hubspotVendorFormTwo"
          })
        }
      })
    }
  }
</script>

<style lang="scss">

  /*HEADER/HERO*/
  .contact-hero {
    background-image: url(../assets/img/contact-hero.jpg);
    background-position: center;
    -webkit-background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-hero h2 {
    color: #fff;
    padding-top: 35px;
    font-size: 26px;
    letter-spacing: 6px;
    font-weight: 100;
  }

  /*CONTACT FORM*/
  .contact-form-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 60px 0;
    flex-direction: row;
  }

  // .contact-info { width: 40%; }
  .contact-info .address p a {
    color: inherit;
    transition: all 0.125s linear;
    display: block;
    padding-top: 15px;
  }
  .contact-info .address p a:hover { color: #00B1FF; }
  .contact-info h2 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 30px;
  }

  span.orange {
    color: #ff6b00;
    cursor: pointer;
  }

  .socials img {
    max-width: 35px;
    padding-right: 10px;
  }

  /*NEW HUBSPOT*/
  
  ::-webkit-input-placeholder { opacity: 1; }
  ::-moz-placeholder { opacity: 1; }

  p.learn-more-heading {
    font-size: 34px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  p.center-align { text-align: center; }

  .contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    #hubspotVendorFormTwo { margin-top: 30px; }

    form { width: 100% !important; }

    fieldset { max-width: unset !important; }
  }

  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 * { color: #636466 !important; }

  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 .hs-button.primary.large { color: #fff !important; }
  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 .hs-button.primary.large:hover { color: #636466 !important; }

  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 .input { margin-top: 20px; }

  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 ul.inputs-list.multi-container { list-style-type: none; }

  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 ul.inputs-list.multi-container label.hs-form-checkbox-display {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    

    input {
      width: 20px !important;
      height: 20px !important;
      margin-right: 10px;
    }
  }
  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 a {
    color: #00b1ff !important;
    text-decoration: underline;
  }

  .hs-form-field {
    position: relative;
    margin-bottom: 30px;

    .hs-input {
      width: 100% !important;
      background-color: #fff;
    }

    input {
      width: 100%;
      box-sizing: border-box;
      
      &.hs-input.invalid.error { border: 1px solid #FD0000 !important; }
    }
  }

  #hsForm_71bb60cd-da86-4012-9c42-1c2566712367 .hs-error-msgs {
    padding: 0 0 0 10px;
    margin: 0;
    list-style: none;
    position: relative !important;
    top: 0 !important;
    
    li { 
      font-size: 12px;

      .hs-error-msg { color: #FD0000 !important; }
    }
  }

  .hs_error_rollup ul {
    margin: 0 0 20px 0;
    padding: 0 0 0 10px;
    list-style: none;

    li label { color: #FD0000; }
  }

  .contact-form input,
  .contact-form textarea {
    border: 1px solid #D4D9E2;
    border-radius: 3px;
    padding: 15px;
    font-size: 14px;
    color: #596377;
    outline: 0;
    transition: all 0.2s linear;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border: 1px solid #000;
  }
  .contact-form textarea {
    min-height: 200px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
  }

  .contact-form select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 1em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    height: 46px;
    border: 1px solid #D4D9E2;
    border-radius: 3px;
    padding: 15px;
    font-size: 14px;
    color: #596377;
    outline: 0;
  }

  .hs-button.primary.large {
    width: fit-content;
    width: -moz-fit-content;
    text-align: center;
    color: #fff;
    background-color: #00B1FF;
    background-image: none !important;
    padding: 10px 20px;
    font-size: 18px !important;
    transition: 0.25s;
    border: 2px solid #00B1FF;
    position: relative;
    border-radius: 10px;
    margin: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-family: mr-eaves-xl-modern, sans-serif;

    &:hover {
      background-color: #fff;
      color: #00B1FF;
    }
  }

  @-moz-document url-prefix() { 
    .contact-hero h2 { font-weight: 300 !important; }
  }

  @media (max-width: 800px) {
    .contact-form-container { flex-direction: column; }

    .new-template-one-page-container .our-offices h2 { font-size: 40px; }

    .contact-info {
      width: 100%;
      margin-bottom: 30px;
    }
    .contact-form { width: 100%; }

    .addresses .row { flex-direction: column; }
    .addresses .row .address { width: 60%; }
  }
</style>
